export const LoadingModal = () => (
  <div className="info_modal">
    <div className="loading_modal_background">
      <div className="loader_container">
        <div className="loader"></div>
      </div>

      <div>
        <p className="loading_modal_title">Prosimy czekać...</p>
      </div>
      <div>
        <p className="loading_modal_subtitle">Trwa generowanie wyników</p>
      </div>
    </div>
  </div>
);
