import { z } from "zod";

export const questionTypeSchema = z.union([
  z.literal("radio"),
  z.literal("form"),
  z.literal("multiselect"),
  z.literal("agreements"),
]);

export const conditionTypeSchema = z.union([
  z.literal("add"),
  z.literal("hide"),
]);

export const validationFieldTypeSchema = z.union([
  z.literal("text"),
  z.literal("email"),
]);

export const conditionSchema = z.object({
  type: conditionTypeSchema,
  questionIds: z.array(z.string()).optional(),
  submoduleIds: z.array(z.string()).optional(),
});

export const validationSchema = z.object({
  minLength: z.union([z.number(), z.string()]),
  maxLength: z.union([z.number(), z.string()]),
  type: validationFieldTypeSchema,
});

export const answerSchema = z.object({
  answerId: z.string(),
  answerName: z.string(),
  noticeHeader: z.string().optional(),
  notice: z.string().optional(),
  condition: z.tuple([conditionSchema]).optional().nullable(),
  validation: validationSchema.optional(),
});

export const questionSchema = z.object({
  questionId: z.string(),
  type: questionTypeSchema,
  questionName: z.string(),
  answers: z.array(answerSchema),
  hint: z.string(),
  hidden: z.boolean().optional(),
  declaration: z.string(),
});

export const submoduleSchema = z.object({
  submoduleId: z.string(),
  submoduleName: z.string(),
  questions: z.array(questionSchema),
  hidden: z.boolean().optional(),
  submoduleTitle: z.string().optional(),
  submoduleDescription: z.string().optional(),
});

export const moduleSchema = z.object({
  moduleId: z.string(),
  moduleName: z.string(),
  submodules: z.array(submoduleSchema),
});

export const dataSchema = z.object({
  modules: z.array(moduleSchema),
});
