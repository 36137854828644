import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { Data } from "../types/api";

export const useMainQuery = () =>
  useQuery(
    ["query"],
    () => axios.get<Data>(process.env.REACT_APP_API_QUERYURL!),
    { retry: 10, retryDelay: 2000 }
  );
