import { useMainContext } from "../context";
import {
  FormCheckboxValue,
  FormRadioValue,
  FormTextValue,
} from "../types/context";
import { isQuestionHidden } from "../utils/form";
import { validateForm } from "../utils/validation";
import { useMainQuery } from "./useMainQuery";

export type useCurrentValidationStateReturnType = ReturnType<
  typeof useCurrentValidationState
>;

export const useCurrentValidationState = () => {
  const { data } = useMainQuery();
  const { navigation, form, formConditions } = useMainContext();

  return data!.data.modules[navigation.module].submodules[
    navigation.submodule
  ].questions.flatMap((question) => {
    if (isQuestionHidden(question, formConditions.current)) {
      return [];
    }

    switch (question.type) {
      case "radio":
        return [
          {
            questionId: question.questionId,
            wasAnswered: !!(form[question.questionId].value as FormRadioValue)
              .value,
          },
        ];

      case "form":
        const isEveryTextInputValid = () => {
          for (const key in form[question.questionId].value as FormTextValue) {
            const value = (form[question.questionId].value as FormTextValue)[
              key
            ].value;
            const validation = (
              form[question.questionId].value as FormTextValue
            )[key].validation;

            if (!validateForm(value, validation)) {
              return false;
            }
          }
          return true;
        };

        return [
          {
            questionId: question.questionId,
            wasAnswered: isEveryTextInputValid(),
          },
        ];

      case "multiselect":
        return [
          {
            questionId: question.questionId,
            wasAnswered:
              (form[question.questionId].value as FormCheckboxValue).length > 0,
          },
        ];

      // "agreements"
      default:
        const isEveryAgreementValid = () => {
          const values = form[question.questionId].value as FormCheckboxValue;

          const isAllAnswered = question.answers.every((answer) => {
            if (!answer.required) {
              return true;
            }

            return values.some((value) => value.value === answer.answerId);
          });

          return isAllAnswered;
        };

        return [
          {
            questionId: question.questionId,
            wasAnswered: isEveryAgreementValid(),
          },
        ];
    }
  });
};
