import { useState } from "react";
import { QuestionComponentProps } from "../types/components";
import { Modal } from "./Modal";
import { QuestionMarkButton } from "./primitives";
import { Checkbox } from "./primitives/inputs";

export const CheckboxQuestion = ({ question }: QuestionComponentProps) => {
  const [modalOn, setModalOn] = useState(false);

  const modalHandler = () => {
    setModalOn(!modalOn);
  };

  return (
    <div className="step" data-step="step-1">
      <div className="question__wrapper" data-question="id-pytania, dla modala">
        <div className="question__title">
          <h2 className="question_name_container">{question.questionName}</h2>
          {question.hint !== "" && (
            <QuestionMarkButton buttonHandler={modalHandler} />
          )}
        </div>
        {modalOn ? (
          <Modal
            question={question}
            modalHandler={modalHandler}
            modalOn={modalOn}
            setModalOn={setModalOn}
          />
        ) : null}
        <div className="modal">
          <div className="question__answer">
            {question.answers.map((answer) => (
              <Checkbox
                key={answer.answerId}
                question={question}
                answer={answer}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
