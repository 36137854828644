export const scrollToStart = () => {
  const html = document.querySelector<HTMLElement>("html");

  if (html) {
    setTimeout(() => {
      html.scrollIntoView({
        block: "start",
      });
    }, 0);
  }
};
