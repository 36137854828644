import clsx from "clsx";

import { useMainContext } from "../../context";
import { Module } from "../../types/api";
import { isSubmoduleHidden } from "../../utils/form";

interface Props {
  module: Module;
  index: number;
}
export const ModulesList = ({ module, index }: Props) => {
  const { navigation, formConditions } = useMainContext();

  return (
    <div className="module_list_element">
      <div
        key={module.moduleId}
        className={clsx(
          navigation.module >= index ? "tab-active" : "tab-inactive"
        )}
        data-tab="tab1"
      >
        <p
          className={clsx(
            navigation.module === index ? "tab-title-active" : "tab-title"
          )}
        >
          {module.moduleName}
        </p>

        {module.submodules.flatMap((submodule, subIndex) => {
          if (isSubmoduleHidden(submodule, formConditions.current)) {
            return [];
          }
          if (submodule.submoduleName === "") {
            return [];
          } else {
            return [
              <p
                key={submodule.submoduleId}
                className={clsx(
                  navigation.module === index &&
                    navigation.submodule === subIndex
                    ? "tab-subtitle-active"
                    : "tab-subtitle"
                )}
              >
                {submodule.submoduleName}
              </p>,
            ];
          }
          // return [

          //   <p
          //     key={submodule.submoduleId}
          //     className={clsx(
          //       navigation.module === index && navigation.submodule === subIndex
          //         ? "tab-subtitle-active"
          //         : "tab-subtitle"
          //     )}
          //   >
          //     {submodule.submoduleName}
          //   </p>,
          // ];
        })}
      </div>
    </div>
  );
};
