import { useMainContext } from "../../../context";
import { Answer, Question } from "../../../types/api";
import { FormCheckboxValue } from "../../../types/context";

type Props = {
  question: Question;
  answer: Answer;
};

export const Checkbox = ({ question, answer }: Props) => {
  const { form, handleCheckboxInput } = useMainContext();

  return (
    <div className="question__container" key={answer.answerId}>
      <div className="label_div">
        <label className="question__label" htmlFor={answer.answerId}>
          <div className="input_container">
            {" "}
            <input
              id={answer.answerId}
              type="checkbox"
              value={answer.answerId}
              onChange={(e) =>
                handleCheckboxInput(question, e.target.value, answer)
              }
              checked={(
                form[question.questionId].value as FormCheckboxValue
              ).some((element) => element.value === answer.answerId)}
            />
          </div>
          {answer.answerName}
        </label>
      </div>
    </div>
  );
};
