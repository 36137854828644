import { QuestionComponentProps } from "../types/components";
import { TextInput } from "./primitives/inputs/TextInput";

export const FormQuestion = ({ question }: QuestionComponentProps) => (
  <div className="step" data-step="step-3">
    <h2 className="question_name_container title2">{question.questionName}</h2>
    {question.answers.map((answer) => (
      <TextInput key={answer.answerId} question={question} answer={answer} />
    ))}
  </div>
);
