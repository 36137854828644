import clsx from "clsx";

import { useMainContext } from "../context";
import { useCurrentSubmoduleFilter } from "../hooks/useCurrentSubmodulesFilter";
import { useCurrentValidationState } from "../hooks/useCurrentValidationState";
import { MainMutation } from "../hooks/useMainMutation";
import { useMainQuery } from "../hooks/useMainQuery";
import { isQuestionHidden, questionCurrentState } from "../utils/form";
import { Buttons } from "./Buttons";
import { CheckboxQuestion } from "./CheckboxQuestion";
import { FormQuestion } from "./FormQuestion";
import { AgreementsQuestion, Dot } from "./primitives";
import { SubmoduleInfo } from "./primitives/SubmoduleInfo";
import { RadioButtonsQuestion } from "./RadioButtonQuestion";

type Props = { onSubmit: MainMutation };
export const FormRenderer = ({ onSubmit }: Props) => {
  const { navigation, formConditions } = useMainContext();
  const { data } = useMainQuery();
  //c
  const questionsCurrentValidationState = useCurrentValidationState();
  const isAllQuestionsAnswered = questionsCurrentValidationState.some(
    (element) => element.wasAnswered === false
  );
  const currentSubmodulesFilter = useCurrentSubmoduleFilter();

  return (
    <>
      <div className="top-nav">
        {data?.data.modules[navigation.module].submodules[navigation.submodule]
          .submoduleName === "" ? (
          ""
        ) : (
          <div className="sub-moduleName">
            {
              data?.data.modules[navigation.module].submodules[
                navigation.submodule
              ].submoduleName
            }
          </div>
        )}

        {data?.data.modules[navigation.module].submodules[navigation.submodule]
          .submoduleName === "" ? (
          ""
        ) : (
          <div className="phone-module-display">
            {currentSubmodulesFilter().map((submodule, index) => (
              <div
                key={submodule.submoduleId}
                className={clsx(
                  submodule.submoduleName ===
                    data?.data.modules[navigation.module].submodules[
                      navigation.submodule
                    ].submoduleName
                    ? "phone-submodule-name-active"
                    : "phone-submodule-name"
                )}
              >
                {submodule.submoduleName}
              </div>
            ))}
          </div>
        )}

        <div className="dots">
          {data?.data.modules[navigation.module].submodules[
            navigation.submodule
          ].questions.map(
            (question) =>
              !isQuestionHidden(question, formConditions.current) && (
                <Dot
                  key={question.questionId}
                  type={questionCurrentState(
                    question,
                    questionsCurrentValidationState
                  )}
                />
              )
          )}
        </div>
      </div>
      <SubmoduleInfo />
      <div>
        {data?.data.modules[navigation.module].submodules[
          navigation.submodule
        ].questions.flatMap((question) => {
          if (
            isQuestionHidden(question, formConditions.current) ||
            questionCurrentState(question, questionsCurrentValidationState) ===
              "future"
          ) {
            return [];
          }

          switch (question.type) {
            case "radio":
              return [
                <RadioButtonsQuestion
                  key={question.questionId}
                  question={question}
                />,
              ];

            case "form":
              return [
                <FormQuestion key={question.questionId} question={question} />,
              ];

            case "agreements":
              return [
                <AgreementsQuestion
                  key={question.questionId}
                  question={question}
                />,
              ];

            // "multiselect"
            default:
              return [
                <CheckboxQuestion
                  key={question.questionId}
                  question={question}
                />,
              ];
          }
        })}
      </div>
      <Buttons onSubmit={onSubmit} isNextDisabled={isAllQuestionsAnswered} />
    </>
  );
};
