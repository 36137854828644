import { useMainQuery } from "../../hooks/useMainQuery";
import { SuspenseWithFlag } from "./SuspenseWithFlag";

type Props = { children: JSX.Element };
export const PreloadContentWrapper = ({ children }: Props) => {
  const query = useMainQuery();

  return (
    <SuspenseWithFlag isSuspended={query.isLoading}>
      {children}
    </SuspenseWithFlag>
  );
};
