import { cloneDeep } from "lodash";
import { useCurrentValidationStateReturnType } from "../hooks/useCurrentValidationState";
import { Data, Question, Submodule } from "../types/api";
import { Form, FormConditions } from "../types/context";

export const isQuestionExplicitlyDisabled = (
  questionId: Question["questionId"],
  formConditions: FormConditions
) => {
  for (const qId in formConditions) {
    for (const aId in formConditions[qId]) {
      if (formConditions[qId][aId].questionIds) {
        // type narrowing fails here, need non null assertion
        for (const id of formConditions[qId][aId].questionIds!) {
          if (formConditions[qId][aId].type === "hide" && questionId === id) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const isQuestionExplicitlyEnabled = (
  questionId: Question["questionId"],
  formConditions: FormConditions
) => {
  let questionHasAdd = false;
  let questionHasHide = false;

  for (const qId in formConditions) {
    for (const aId in formConditions[qId]) {
      if (formConditions[qId][aId].questionIds) {
        // type narrowing fails here, need non null assertion
        formConditions[qId][aId].questionIds!.forEach((id) => {
          if (formConditions[qId][aId].type === "add" && questionId === id) {
            questionHasAdd = true;
          }

          if (formConditions[qId][aId].type === "hide" && questionId === id) {
            questionHasHide = true;
          }
        });
      }
    }
  }

  if (questionHasAdd && !questionHasHide) {
    return true;
  }
  return false;
};

export const isQuestionHidden = (
  question: Question,
  formConditions: FormConditions
) => {
  if (
    !isQuestionExplicitlyEnabled(question.questionId, formConditions) &&
    (question.hidden ||
      isQuestionExplicitlyDisabled(question.questionId, formConditions))
  ) {
    return true;
  }
  return false;
};

const isSubmoduleExplicitlyDisabled = (
  submodule: Submodule,
  formConditions: FormConditions
) => {
  for (const submoduleId in formConditions) {
    for (const answerId in formConditions[submoduleId]) {
      if (formConditions[submoduleId][answerId].submoduleIds) {
        // type narrowing fails here, need non null assertion
        for (const id of formConditions[submoduleId][answerId].submoduleIds!) {
          if (
            formConditions[submoduleId][answerId].type === "hide" &&
            submodule.submoduleId === id
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

const isSubmoduleExplicitlyEnabled = (
  submodule: Submodule,
  formConditions: FormConditions
) => {
  for (const submoduleId in formConditions) {
    for (const answerId in formConditions[submoduleId]) {
      if (formConditions[submoduleId][answerId].submoduleIds) {
        // type narrowing fails here, need non null assertion
        for (const id of formConditions[submoduleId][answerId].submoduleIds!) {
          if (
            formConditions[submoduleId][answerId].type === "add" &&
            submodule.submoduleId === id
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const isSubmoduleHidden = (
  submodule: Submodule,
  formConditions: FormConditions
) => {
  if (
    !isSubmoduleExplicitlyEnabled(submodule, formConditions) &&
    (submodule.hidden ||
      isSubmoduleExplicitlyDisabled(submodule, formConditions))
  ) {
    return true;
  }
  return false;
};

const isMemberOfHiddenSubmodule = (
  questionId: Question["questionId"],
  data: Data,
  formConditions: FormConditions,
) => {
  for (const module of data.modules) {
    for (const submodule of module.submodules) {
      if (
        isSubmoduleHidden(submodule, formConditions) &&
        submodule.questions.some(
          (question) => question.questionId === questionId
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

export const filterForm = (
  form: Form,
  formConditions: FormConditions,
  data: Data
): Form => {
  const tempForm = cloneDeep(form);

  for (const [questionId, value] of Object.entries(form)) {
    if (
      (!isQuestionExplicitlyEnabled(questionId, formConditions) &&
        (value.hidden ||
          isQuestionExplicitlyDisabled(questionId, formConditions))) ||
      isMemberOfHiddenSubmodule(questionId, data, formConditions)
    ) {
      delete tempForm[questionId];
    }
  }

  return tempForm;
};

export const questionCurrentState = (
  question: Question,
  questionsCurrentValidationState: useCurrentValidationStateReturnType
): "past" | "current" | "future" | never => {
  let wasAnsweredOnceFalse = false;
  let isAfterFalseOccured = false;

  for (const element of questionsCurrentValidationState) {
    if (wasAnsweredOnceFalse) {
      isAfterFalseOccured = true;
    }

    if (!element.wasAnswered) {
      wasAnsweredOnceFalse = true;
    }

    if (element.questionId === question.questionId) {
      if (wasAnsweredOnceFalse && !isAfterFalseOccured) {
        return "current";
      } else if (wasAnsweredOnceFalse && isAfterFalseOccured) {
        return "future";
      } else {
        return "past";
      }
    }
  }
  throw new Error("Question not found in questionCurrentState function");
};
