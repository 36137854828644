import closeButton from "../../assets//images/close_button.svg";
interface Props {
  buttonHandler: () => void;
}

export const CloseButton = ({ buttonHandler }: Props) => {
  return (
    <div className="button_exit">
      <button className="modal_button_close" onClick={() => buttonHandler()}>
        {" "}
        <img src={closeButton} className="modal_close_button" alt="zamknij" />
      </button>
    </div>
  );
};
