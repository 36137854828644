import { useState } from "react";

import { CookiesModal } from "./components/CookiesModal";
import { FormRenderer } from "./components/FormRenderer";
import { LoadingModal } from "./components/primitives";
import { ModulesList } from "./components/primitives/ModulesList";
import { SuccessAnnouncement } from "./components/SuccessAnnouncement";
import { useMainMutation } from "./hooks/useMainMutation";
import { useMainQuery } from "./hooks/useMainQuery";
import { dataSchema } from "./zod/api.zod";

import "./App.css";
import logo from "./assets/images/Group 8833.svg";
import { useCookies } from "./hooks/useCookies";

export const App = () => {
  const { data } = useMainQuery();
  const { mutate, isLoading, isSuccess } = useMainMutation();
  const [cookies, setCookie] = useCookies();
  const [example, setExample] = useState(false);

  const zod = dataSchema.safeParse(data?.data);
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);

  if (
    cookies.advertisingCookies === undefined &&
    cookies.mandatoryCookies === undefined &&
    cookies.controlCookie === undefined &&
    cookies.performanceCookies === undefined
  ) {
    setCookie("controlCookie", true, { path: "/", expires: currentDate });
    setCookie("advertisingCookies", false, {
      path: "/",
      expires: currentDate,
    });
    setCookie("mandatoryCookies", false, {
      path: "/",
      expires: currentDate,
    });
    setCookie("performanceCookies", false, {
      path: "/",
      expires: currentDate,
    });
  }

  if (!zod.success) {
    console.warn(zod.error.issues);
  }

  return (
    <>
      <CookiesModal example={example} setExample={setExample} />
      {isLoading && <LoadingModal />}
      <div className="App">
        <div className="body">
          <div className="wrapper">
            <div className="headingclass">
              <div className="logos">
                <img src={logo} alt="" />
              </div>
              {!isSuccess && (
                <div className="heading">
                  <p className="title">{data?.data["form-title"]}</p>
                  <p className="sub-title">{data?.data["form-description"]}</p>
                </div>
              )}
            </div>
            {isSuccess && <SuccessAnnouncement />}
            <div className="tab-row">
              {!isSuccess && (
                <>
                  <div className="tabs">
                    {data?.data.modules.map((module, index) => (
                      <ModulesList
                        key={module.moduleId}
                        module={module}
                        index={index}
                      />
                    ))}
                  </div>
                  <div className="steps">
                    <FormRenderer onSubmit={mutate} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="cookies-settings">
          <a
            className="cookies-settings-texts"
            onClick={() =>
              setCookie("controlCookie", true, {
                path: "/",
                expires: currentDate,
              })
            }
          >
            Ustawienia cookies
          </a>
        </div>
      </div>
    </>
  );
};
