import { AnimatePresence, motion, AnimatePresenceProps } from "framer-motion";

import { FullscreenLoader } from "../../loaders/FullscreenLoader";

import { animationVariants } from "./SuspenseWithFlag.animations";
import styles from "./SuspenseWithFlag.module.css";

type Props = {
  isSuspended: boolean;
  children: React.ReactNode;
  fallback?: JSX.Element;
  renderAsDisplayNone?: boolean;
  animatePresenceMode?: AnimatePresenceProps["mode"]
};
export const SuspenseWithFlag = ({
  isSuspended,
  children,
  fallback = <FullscreenLoader scale={2} />,
  renderAsDisplayNone = false,
  animatePresenceMode = "wait"
}: Props) => (
  <>
    {renderAsDisplayNone && (
      <AnimatePresence mode={animatePresenceMode}>
        {isSuspended && (
          <motion.div
            key="SuspenseWithFlag-fallback"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={animationVariants}
            className={styles.fallbackContainer}
          >
            {fallback}
          </motion.div>
        )}

        <motion.div
          key="SuspenseWithFlag-children"
          initial={{ display: "none", opacity: 0 }}
          animate={{
            display: isSuspended ? "none" : "initial",
            opacity: isSuspended ? 0 : 1,
            transition: { type: "tween", duration: 0.2, ease: "easeInOut" },
          }}
          className={styles.childrenContainer}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    )}

    {!renderAsDisplayNone && (
      <AnimatePresence mode={animatePresenceMode}>
        {isSuspended ? (
          <motion.div
            key="SuspenseWithFlag-fallback"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={animationVariants}
            className={styles.fallbackContainer}
          >
            {fallback}
          </motion.div>
        ) : (
          <motion.div
            key="SuspenseWithFlag-children"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={animationVariants}
            className={styles.childrenContainer}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    )}
  </>
);
