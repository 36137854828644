import { useState } from "react";
import { useMainContext } from "../context";
import { QuestionComponentProps } from "../types/components";
import { FormRadioValue } from "../types/context";
import { Announcement } from "./Announcement";
import { Modal } from "./Modal";
import { Radio } from "./primitives/inputs/Radio";
import { QuestionMarkButton } from "./primitives/QuestionMarkButton";

export const RadioButtonsQuestion = ({ question }: QuestionComponentProps) => {
  const { form } = useMainContext();

  const [modalOn, setModalOn] = useState(false);

  const modalHandler = () => {
    setModalOn(!modalOn);
  };

  const formRadioValue = form[question.questionId].value as FormRadioValue;

  return (
    <>
      <div className="step" data-step="step-1">
        <div
          className="question__wrapper"
          data-question="id-pytania, dla modala"
        >
          <div className="question__title">
            <h2 className="question_name_container">
              {question?.questionName}
            </h2>

            {question.hint !== "" && (
              <QuestionMarkButton buttonHandler={modalHandler} />
            )}
          </div>

          {modalOn && (
            <Modal
              question={question}
              modalHandler={modalHandler}
              modalOn={modalOn}
              setModalOn={setModalOn}
            />
          )}
          <div className="modal">
            <div className="question__answer">
              {question.answers.map((answer) => (
                <Radio
                  key={answer.answerId}
                  question={question}
                  answer={answer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {((typeof formRadioValue.noticeHeader === "string" &&
        formRadioValue.noticeHeader.length > 0) ||
        (typeof formRadioValue.notice === "string" &&
          formRadioValue.notice.length > 0)) && (
        <Announcement
          title={formRadioValue.noticeHeader ?? ""}
          message={formRadioValue.notice ?? ""}
        />
      )}
    </>
  );
};
