import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";

import { Form } from "../types/context";
import { buildFormData } from "../utils/api";

export type MainMutation = ReturnType<typeof useMainMutation>["mutate"];
export const useMainMutation = () =>
  useMutation<AxiosResponse, AxiosError, Form>((data) =>
    axios.post(process.env.REACT_APP_API_MUTATIONURL!, buildFormData(data), {
      headers: { "Content-Type": "multipart/form-data" },
    })
  );
