import clsx from "clsx";

type Props = {
  type: "next_small" | "future" | "past" | "current";
};

export const Dot = ({ type }: Props) => (
  <div
    className={clsx(
      type === "next_small" && "next_small",
      type === "future" && "next",
      type === "past" && "past",
      type === "current" && "current"
    )}
  ></div>
);
