import PiwikPro from "@piwikpro/react-piwik-pro";
import { useEffect, useRef } from "react";

type Options = { performance: boolean; advertising: boolean };
export const useTrackingScripts = ({ performance, advertising }: Options) => {
  const piwikWasInitialized = useRef(false);

  useEffect(() => {
    if (performance && !piwikWasInitialized.current) {
      PiwikPro.initialize(
        "899ba08d-5424-4b6b-bed2-5735b6cd18f3",
        "https://kalkulator-feng.containers.piwik.pro"
      );

      piwikWasInitialized.current = true;
    }
  }, []);
};
