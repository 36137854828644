import alertIcon from "../assets/images/alertIcon.svg";

type Props = { title: string; message: string };
export const Announcement = ({ title, message }: Props) => (
  <div className="step">
    <div className="announcement_body">
      <div className="alertIcon_container">
        <img src={alertIcon} alt="alert" />
      </div>
      <div>
        {title.length > 0 && <p className="announcement_title">{title}</p>}
      </div>
      <div>
        {message.length > 0 && (
          <p className="announcement_context">{message}</p>
        )}
      </div>
    </div>
  </div>
);
