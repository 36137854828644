import clsx from "clsx";
import { useState } from "react";

import { useMainContext } from "../../../context";
import { Answer, Question } from "../../../types/api";
import { FormTextValue } from "../../../types/context";
import { validateForm } from "../../../utils/validation";

type Props = { question: Question; answer: Answer };
export const TextInput = ({ question, answer }: Props) => {
  const { form, handleTextInput } = useMainContext();

  const [isValidationActive, setIsValidationActive] = useState(false);

  const valid = validateForm(
    (form[question.questionId].value as FormTextValue)[answer.answerId].value,
    answer.validation
  );

  return (
    <input
      key={answer.answerId}
      className={clsx(
        isValidationActive && !valid ? "step__input_notValid" : "step__input"
      )}
      placeholder={answer.answerName + "*"}
      value={
        (form[question.questionId].value as FormTextValue)[answer.answerId]
          .value
      }
      onChange={(e) =>
        handleTextInput(question, answer.answerId, e.target.value, answer)
      }
      onBlur={() => {
        if (!isValidationActive) {
          setIsValidationActive(true);
        }
      }}
    />
  );
};
