import parse from "html-react-parser";

import { useMainContext } from "../context";
import { Answer, Question } from "../types/api";
import { QuestionComponentProps } from "../types/components";
import { FormCheckboxValue } from "../types/context";

type Props = { question: Question; answer: Answer };
export const AgreementsAnswer = ({ question, answer }: Props) => {
  const { form, handleCheckboxInput } = useMainContext();

  const isChecked = (form[question.questionId].value as FormCheckboxValue).some(
    (element) => element.value === answer.answerId
  );

  return (
    <label key={answer.answerId} className="checkbox__content">
      <div className="checkbox__input">
        <input
          id={answer.answerId}
          type="checkbox"
          value={answer.answerId}
          onChange={(e) =>
            handleCheckboxInput(question, e.target.value, answer)
          }
          checked={isChecked}
        />
      </div>
      <div className="checkbox-input-label">{parse(answer.answerName)}</div>
    </label>
  );
};

export const AgreementsQuestion = ({ question }: QuestionComponentProps) => {
  return (
    <div className="step" data-step="step-4">
      <h2 className="title2">{question.questionName}</h2>
      <div className="step4">
        <div className="checkbox__container">
          <div className="checkbox-declaration">
            {parse(question.declaration)}
          </div>
          {question.answers.map((answer) => (
            <AgreementsAnswer answer={answer} question={question} />
          ))}
        </div>
      </div>
      <p className="required__field">*Pole wymagane</p>
    </div>
  );
};
