import { useMainContext } from "../../../context";
import { Answer, Question } from "../../../types/api";
import { FormRadioValue } from "../../../types/context";
import { autoScroll } from "../../../utils/autoScroll";

type Props = { question: Question; answer: Answer };
export const Radio = ({ question, answer }: Props) => {
  const { form, handleRadioInput } = useMainContext();

  return (
    <div className="question__container">
      <div className="label_div">
        <label className="question__label" htmlFor={answer.answerId}>
          <div className="input_container">
            {" "}
            <input
              type="radio"
              value={answer.answerId}
              id={answer.answerId}
              name={answer.answerId}
              onChange={(e) => {
                handleRadioInput(question, e.target.value, answer);
                autoScroll();
              }}
              checked={
                (form[question.questionId].value as FormRadioValue).value ===
                answer.answerId
              }
            />
            <span className="input_radio" />
          </div>
          {answer.answerName}
        </label>
      </div>
    </div>
  );
};
