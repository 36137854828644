import clsx from "clsx";

type Props = {
  type: "back" | "next" | "submit" | "homepage";
  onClick?: () => void;
  disabled?: boolean;
};
export const Button = ({ type, disabled, onClick }: Props) => (
  <button
    className={clsx(
      type === "back" && "button_back",
      (type === "next" || type === "submit" || type === "homepage") &&
        "button_next"
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {type === "back" && (
      <>
        <i className="arrow_left"></i>Wróć
      </>
    )}
    {type === "next" && (
      <>
        Dalej <i className="arrow_right"></i>
      </>
    )}
    {type === "submit" && "Sprawdź wyniki"}
    {type === "homepage" && "Powrót na stronę główną"}
  </button>
);
