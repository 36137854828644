import { set, unset } from "lodash";
import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { useMainQuery } from "../hooks/useMainQuery";
import { Question } from "../types/api";
import {
  Form,
  FormCheckboxValue,
  FormConditions,
  FormHandler,
  FormTextValue,
  Navigation,
  PossibleFormValues,
  TextFormHandler,
} from "../types/context";
import { isSubmoduleHidden } from "../utils/form";

type MainContextType = {
  navigation: Navigation;
  isCurrentModuleFirst: boolean;
  isCurrentSubmoduleFirst: boolean;
  isCurrentModuleLast: boolean;
  isCurrentSubmoduleLast: boolean;
  goForward: () => void;
  goBack: () => void;

  form: Form;
  formConditions: MutableRefObject<FormConditions>;
  handleTextInput: TextFormHandler;
  handleRadioInput: FormHandler;
  handleCheckboxInput: FormHandler;
};

const MainContext = createContext<MainContextType | null>(null);

type Props = { children: JSX.Element };
export const MainContextProvider = ({ children }: Props) => {
  const { data } = useMainQuery();

  // Form conditions
  const formConditions = useRef<FormConditions>({});

  // Navigation logic
  const [navigation, setNavigation] = useState<MainContextType["navigation"]>({
    module: 0,
    submodule: 0,
  });

  const modulesLength = useMemo(() => data!.data.modules.length, [data]);

  const allSubmodulesLength = useMemo(
    () => data!.data.modules[navigation.module].submodules.length,
    [data, navigation.module]
  );
  const visibleSubmodulesLength = useMemo(() => {
    let count = 0;
    for (const submodule of data!.data.modules[navigation.module].submodules) {
      if (!isSubmoduleHidden(submodule, formConditions.current)) {
        count++;
      }
    }
    return count;
  }, [data, navigation.module]);

  const isCurrentModuleFirst = useMemo<MainContextType["isCurrentModuleFirst"]>(
    () => navigation.module === 0,
    [navigation.module]
  );

  const isCurrentSubmoduleFirst = useMemo<
    MainContextType["isCurrentSubmoduleFirst"]
  >(() => {
    for (let i = navigation.submodule - 1; i >= 0; i--) {
      if (!data?.data.modules[navigation.module].submodules[i]) {
        return true;
      }
      if (
        !isSubmoduleHidden(
          data!.data.modules[navigation.module].submodules[i],
          formConditions.current
        )
      ) {
        return false;
      }
    }
    return true;
  }, [data, navigation]);

  const isCurrentModuleLast = useMemo<MainContextType["isCurrentModuleLast"]>(
    () => modulesLength - 1 === navigation.module,
    [modulesLength, navigation.module]
  );

  const isCurrentSubmoduleLast = useMemo<
    MainContextType["isCurrentSubmoduleLast"]
  >(() => {
    for (let i = navigation.submodule + 1; i < allSubmodulesLength; i++) {
      if (!data?.data.modules[navigation.module].submodules[i]) {
        return true;
      }
      if (
        !isSubmoduleHidden(
          data!.data.modules[navigation.module].submodules[i],
          formConditions.current
        )
      ) {
        return false;
      }
    }
    return true;
  }, [data, navigation, allSubmodulesLength]);

  const goForward = useCallback<MainContextType["goForward"]>(() => {
    if (isCurrentSubmoduleLast) {
      // we are at the end of submodule
      for (let i = navigation.module + 1; i < modulesLength; i++) {
        for (let j = 0; j < data!.data.modules[i].submodules.length; j++) {
          if (
            !isSubmoduleHidden(
              data!.data.modules[i].submodules[j],
              formConditions.current
            )
          ) {
            setNavigation({
              module: i,
              submodule: j,
            });
            return;
          }
        }
      }
    } else {
      // we are not at the end of submodule
      for (let i = navigation.module; i < modulesLength; i++) {
        for (let j = navigation.submodule + 1; j < allSubmodulesLength; j++) {
          if (
            !isSubmoduleHidden(
              data!.data.modules[i].submodules[j],
              formConditions.current
            )
          ) {
            setNavigation({
              module: i,
              submodule: j,
            });
            return;
          }
        }
      }
    }
  }, [
    data,
    navigation,
    modulesLength,
    allSubmodulesLength,
    isCurrentSubmoduleLast,
  ]);

  const goBack = useCallback<MainContextType["goBack"]>(() => {
    if (isCurrentSubmoduleFirst) {
      // we are at the beggining of submodule
      for (let i = navigation.module - 1; i >= 0; i--) {
        for (let j = data!.data.modules[i].submodules.length - 1; j >= 0; j--) {
          if (
            !isSubmoduleHidden(
              data!.data.modules[i].submodules[j],
              formConditions.current
            )
          ) {
            setNavigation({
              module: i,
              submodule: j,
            });
            return;
          }
        }
      }
    } else {
      // we are not at the beggining of submodule
      for (let i = navigation.module; i >= 0; i--) {
        for (let j = navigation.submodule - 1; j >= 0; j--) {
          if (
            !isSubmoduleHidden(
              data!.data.modules[i].submodules[j],
              formConditions.current
            )
          ) {
            setNavigation({
              module: i,
              submodule: j,
            });
            return;
          }
        }
      }
    }
  }, [data, navigation, isCurrentSubmoduleFirst]);

  // Form logic
  const initializeValue = (question: Question): PossibleFormValues => {
    switch (question.type) {
      case "form":
        const textInputObject: FormTextValue = {};

        question.answers.forEach((answer) => {
          set(textInputObject, `${answer.answerId}.value`, "");
          set(
            textInputObject,
            `${answer.answerId}.validation`,
            answer.validation
          );
        });
        return textInputObject;
      case "radio":
        return { value: "", notice: "", noticeHeader: "" };
      // "multiselect" | "agreements"
      default:
        return [];
    }
  };

  const initializeFullForm = (): MainContextType["form"] => {
    const questionsTemp: MainContextType["form"] = {};

    data!.data.modules.forEach((module) => {
      module.submodules.forEach((submodule) => {
        submodule.questions.forEach((question) => {
          questionsTemp[question.questionId] = {
            value: initializeValue(question),
            type: question.type,
            hidden: question.hidden,
          };
        });
      });
    });

    return questionsTemp;
  };

  const [form, setForm] = useState<MainContextType["form"]>(
    initializeFullForm()
  );

  const handleTextInput = useCallback<MainContextType["handleTextInput"]>(
    (question, answerId, value, answer) => {
      setForm((state) => ({
        ...state,
        [question.questionId]: {
          value: {
            ...(state[question.questionId].value as FormTextValue),
            [answerId]: { value, validation: answer.validation },
          },
          type: question.type,
          hidden: question.hidden,
        },
      }));

      // text input has UNHANDLED form conditions yet
    },
    []
  );
  const handleRadioInput = useCallback<MainContextType["handleRadioInput"]>(
    (question, value, answer) => {
      setForm((state) => ({
        ...state,
        [question.questionId]: {
          value: {
            value,
            notice: answer.notice,
            noticeHeader: answer.noticeHeader,
          },
          type: question.type,
          hidden: question.hidden,
        },
      }));

      if (answer.condition) {
        formConditions.current[question.questionId] = {
          [answer.answerId]: {
            type: answer.condition[0].type,
            ...(answer.condition[0].questionIds && {
              questionIds: answer.condition[0].questionIds,
            }),
            ...(answer.condition[0].submoduleIds && {
              submoduleIds: answer.condition[0].submoduleIds,
            }),
          },
        };
      } else {
        delete formConditions.current[question.questionId];
      }
    },
    []
  );
  const handleCheckboxInput = useCallback<
    MainContextType["handleCheckboxInput"]
  >(
    (question, value, answer) => {
      if (
        (form[question.questionId].value as FormCheckboxValue).some(
          (element) => element.value === value
        )
      ) {
        // removing checkbox from array
        setForm((state) => ({
          ...state,
          [question.questionId]: {
            value: [
              ...(state[question.questionId].value as FormCheckboxValue).filter(
                (element) => element.value !== value
              ),
            ],
            type: question.type,
            hidden: question.hidden,
          },
        }));

        if (answer.condition) {
          unset(
            formConditions.current,
            `${question.questionId}.${answer.answerId}`
          );
        }
      } else {
        // adding checkbox to array
        setForm((state) => ({
          ...state,
          [question.questionId]: {
            value: [
              ...(state[question.questionId].value as FormCheckboxValue),
              {
                value,
                notice: answer.notice,
                noticeHeader: answer.noticeHeader,
              },
            ],
            type: question.type,
            hidden: question.hidden,
          },
        }));

        if (answer.condition) {
          formConditions.current[question.questionId] = {
            ...formConditions.current[question.questionId],
            [answer.answerId]: {
              type: answer.condition[0].type,
              ...(answer.condition[0].questionIds && {
                questionIds: answer.condition[0].questionIds,
              }),
              ...(answer.condition[0].submoduleIds && {
                submoduleIds: answer.condition[0].submoduleIds,
              }),
            },
          };
        }
      }
    },
    [form]
  );

  const mainContextMemoized = useMemo<MainContextType>(
    () => ({
      navigation,
      modulesLength,
      submodulesLength: visibleSubmodulesLength,
      isCurrentModuleFirst,
      isCurrentSubmoduleFirst,
      isCurrentModuleLast,
      isCurrentSubmoduleLast,
      goForward,
      goBack,
      form,
      formConditions,
      handleTextInput,
      handleRadioInput,
      handleCheckboxInput,
    }),
    [
      navigation,
      modulesLength,
      visibleSubmodulesLength,
      isCurrentModuleFirst,
      isCurrentSubmoduleFirst,
      isCurrentModuleLast,
      isCurrentSubmoduleLast,
      goForward,
      goBack,
      form,
      formConditions,
      handleTextInput,
      handleRadioInput,
      handleCheckboxInput,
    ]
  );
  return (
    <MainContext.Provider value={mainContextMemoized}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext)!;
