import * as Dialog from "@radix-ui/react-dialog";
import parse from "html-react-parser";
import { Question } from "../types/api";
import { CloseButton } from "./../components/primitives/CloseButton";

interface Props {
  question: Question;
  modalHandler: () => void;
  modalOn: boolean;
  setModalOn: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Modal = ({
  question,
  modalHandler,
  modalOn,
  setModalOn,
}: Props) => (
  <Dialog.Root open={modalOn} onOpenChange={modalHandler}>
    <Dialog.Portal>
      <Dialog.Overlay />
      <div className="info_modal">
        <Dialog.Content className="modal-content">
          <Dialog.Title asChild className="modal-header">
            <div>
              <h4 className="modal-header-text">{question.questionName}</h4>
              <CloseButton buttonHandler={modalHandler} />
            </div>
          </Dialog.Title>
          <Dialog.Description asChild className="modal-body">
            <div className="modal-content-text content-p content-h">
              {" "}
              {parse(question.hint)}
            </div>
          </Dialog.Description>
        </Dialog.Content>
      </div>
    </Dialog.Portal>
  </Dialog.Root>
);
