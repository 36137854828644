import { useMainContext } from "../context";
import { MainMutation } from "../hooks/useMainMutation";
import { useMainQuery } from "../hooks/useMainQuery";
import { filterForm } from "../utils/form";
import { scrollToStart } from "../utils/scrollToStart";
import { Button } from "./primitives";

type Props = {
  isNextDisabled: boolean;
  onSubmit: MainMutation;
};
export const Buttons = ({ isNextDisabled, onSubmit }: Props) => {
  const {
    goBack,
    goForward,
    isCurrentModuleFirst,
    isCurrentSubmoduleFirst,
    isCurrentModuleLast,
    isCurrentSubmoduleLast,
    form,
    formConditions,
  } = useMainContext();

  const { data } = useMainQuery();

  return (
    <div className="dual_button">
      {isCurrentModuleLast && isCurrentSubmoduleLast ? (
        <Button
          disabled={isNextDisabled}
          type="submit"
          onClick={() =>
            onSubmit(filterForm(form, formConditions.current, data!.data))
          }
        />
      ) : (
        <>
          <Button
            disabled={isNextDisabled}
            type="next"
            onClick={() => {
              goForward();
              scrollToStart();
            }}
          />
          {((isCurrentModuleFirst && !isCurrentSubmoduleFirst) ||
            !isCurrentModuleFirst) && (
            <Button
              type="back"
              onClick={() => {
                goBack();
                scrollToStart();
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
