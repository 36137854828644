import { useMainContext } from "../../context";
import { useMainQuery } from "../../hooks/useMainQuery";

export const SubmoduleInfo = () => {
  const { navigation } = useMainContext();
  const { data } = useMainQuery();

  if (
    data?.data.modules[navigation.module].submodules[navigation.submodule]
      .submoduleTitle &&
    data?.data.modules[navigation.module].submodules[navigation.submodule]
      .submoduleDescription
  ) {
    return (
      <div className="submodule-info">
        <div className="submodule-info-title">
          {
            data.data.modules[navigation.module].submodules[
              navigation.submodule
            ].submoduleTitle
          }
        </div>
        <div className="submodule-info-description">
          {
            data.data.modules[navigation.module].submodules[
              navigation.submodule
            ].submoduleDescription
          }
        </div>
      </div>
    );
  }
  return null;
};
