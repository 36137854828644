import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { useCookies } from "../hooks/useCookies";

import { useTrackingScripts } from "../hooks/useTrackingScripts";

interface Props {
  example: boolean;
  setExample: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CookiesModal = ({ example, setExample }: Props) => {
  const [customizeCookies, setCustomizeCookies] = useState(false);
  const [cookies, setCookie] = useCookies();

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);

  const [cookiePerformanceState, setCookiePerformanceState] = useState(true);
  const [cookieAdvertisingState, setCookieAdvertisingState] = useState(true);
  const [cookieMandatoryState, setCookieMandatoryState] = useState(true);

  useTrackingScripts({
    advertising: cookies.advertisingCookies === "true",
    performance: cookies.performanceCookies === "true",
  });

  const changeCookieValue = () => {
    setCustomizeCookies(false);
    setCookie("controlCookie", false, {
      path: "/",
      expires: currentDate,
    });

    if (cookieMandatoryState) {
      setCookie("mandatoryCookies", true, {
        path: "/",
        expires: currentDate,
      });
    } else {
      setCookie("mandatoryCookies", false, {
        path: "/",
        expires: currentDate,
      });
    }

    if (cookieAdvertisingState) {
      setCookie("advertisingCookies", true, {
        path: "/",
        expires: currentDate,
      });
      setExample(true);
    } else {
      setExample(false);
      setCookie("advertisingCookies", false, {
        path: "/",
        expires: currentDate,
      });
    }

    if (cookiePerformanceState) {
      setCookie("performanceCookies", true, {
        path: "/",
        expires: currentDate,
      });
      setExample(true);
    } else {
      setExample(false);
      setCookie("performanceCookies", false, {
        path: "/",
        expires: currentDate,
      });
    }

    window.location.reload();
  };

  const acceptAllCookies = () => {
    setExample(true);
    setCookie("performanceCookies", true, {
      path: "/",
      expires: currentDate,
    });
    setCookie("advertisingCookies", true, {
      path: "/",
      expires: currentDate,
    });
    setCookie("mandatoryCookies", true, {
      path: "/",
      expires: currentDate,
    });
    setCookie("controlCookie", false, {
      path: "/",
      expires: currentDate,
    });

    window.location.reload();
  };

  return (
    <Dialog.Root open={cookies.controlCookie === "true"}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <div className="info_modal ">
          <Dialog.Content className="modal-content ">
            <Dialog.Title asChild className="modal-header">
              <div>
                <p className="cookieModalHeader">
                  Ustawienia plików cookies (ciasteczek)
                </p>
              </div>
            </Dialog.Title>
            <Dialog.Description asChild className="modal-body container ">
              <div>
                <div className="cookie-contentSubHeading">
                  Oprócz plików cookie, które są niezbędne do działania tej
                  strony internetowej, używamy następujących rodzajów plików
                  cookie, aby poprawić komfort korzystania z witryny i naszych
                  usług: Funkcjonalne pliki cookie, aby poprawić komfort
                  korzystania z witryny (np. zapamiętać ustawienia),
                  Wydajnościowe pliki cookie, aby zmierzyć wydajność witryny i
                  poprawić komfort korzystania z niej, Reklamowe/targetujące
                  pliki cookie, które są ustawiane przez strony trzecie, z
                  którymi realizujemy kampanie reklamowe i pozwalają nam
                  dostarczać Ci reklamy odpowiednie dla Ciebie. Zapoznaj się z
                  naszą{" "}
                  <a
                    href="https://www.ey.com/pl_pl/cookie-policy"
                    target="_blank"
                  >
                    polityką cookies
                  </a>
                  , aby uzyskać więcej informacji.
                </div>
                {!customizeCookies ? (
                  <div className="cookies-buttonContainer">
                    <button
                      className="cookies-button"
                      onClick={() => acceptAllCookies()}
                    >
                      Akceptuję wszystkie cookies
                    </button>
                    <button
                      className="cookies-button"
                      onClick={() => setCustomizeCookies(!customizeCookies)}
                    >
                      Dostosowywanie cookies
                    </button>
                  </div>
                ) : (
                  <div className="cookies-radioButtonList">
                    <div className="cookie-checkboxContainer">
                      <label className="cookie-checkboxContent">
                        <div className="cookie-checkbox">
                          {" "}
                          <input
                            type="checkbox"
                            onChange={() =>
                              setCookieMandatoryState(!cookieMandatoryState)
                            }
                            checked={cookieMandatoryState}
                          />
                        </div>
                        <div>
                          <p className="cookie-contentHeading">
                            Akceptuję poniższe cookie dotyczące funkcji
                          </p>
                          <p className="cookie-contentSubHeading">
                            Pliki cookie dotyczące funkcji, dzięki którym możemy
                            zwiększyć Twoją satysfakcję z korzystania ze strony
                            (np. dzięki zapamiętywaniu wybranych przez Ciebie
                            ustawień).
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className="cookie-checkboxContainer">
                      <label className="cookie-checkboxContent">
                        <div className="cookie-checkbox">
                          {" "}
                          <input
                            type="checkbox"
                            checked={cookiePerformanceState}
                            onChange={() =>
                              setCookiePerformanceState(!cookiePerformanceState)
                            }
                          />
                        </div>
                        <div>
                          <p className="cookie-contentHeading">
                            Akceptuję poniższe cookie dotyczące wydajności
                            strony
                          </p>
                          <p className="cookie-contentSubHeading">
                            Cookie dotyczące wydajności strony, dzięki którym
                            możemy oceniać jakość strony i zwiększyć Twoją
                            satysfakcję z korzystania ze strony. W trakcie
                            używania cookie dotyczących jakości nie gromadzimy
                            żadnych danych osobowych i wykorzystujemy wyłącznie
                            informacje zebrane za pośrednictwem tych plików w
                            formie zagregowanej i anonimowej.
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className="cookie-checkboxContainer">
                      <label className="cookie-checkboxContent">
                        <div className="cookie-checkbox">
                          {" "}
                          <input
                            type="checkbox"
                            checked={cookieAdvertisingState}
                            onChange={() =>
                              setCookieAdvertisingState(!cookieAdvertisingState)
                            }
                          />
                        </div>
                        <div>
                          <p className="cookie-contentHeading">
                            Akceptuję poniższe cookie dotyczące reklamy/
                            targetowania
                          </p>
                          <p className="cookie-contentSubHeading">
                            Marketingowe/targetujące pliki cookie, których
                            używamy do śledzenia aktywności i sesji użytkownika,
                            abyśmy mogli świadczyć bardziej spersonalizowane
                            usługi. Marketingowe pliki cookie są ustawiane przez
                            strony trzecie, z którymi realizujemy kampanie
                            marketingowe i umożliwiają nam dostarczanie
                            odpowiednich dla Ciebie materiałów marketingowych.
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className="cookies-buttonContainer">
                      <button
                        className="button_next"
                        onClick={() => changeCookieValue()}
                      >
                        Zapisz mój wybór
                      </button>
                    </div>
                    <div className="cookie-table-wrapper">
                      <p className="cookie-contentHeading">Funkcjonalne</p>
                      <table className="cookies-table">
                        <tr>
                          <th>Nazwa pliku cookie</th>
                          <th>Właściciel pliku cookie</th>
                          <th>Cel pliku cookie</th>
                          <th>Rodzaj pliku cookie</th>
                          <th>Okres ważności pliku cookie</th>
                        </tr>
                        <tr>
                          <td>
                            controlCookie, advertisingCookies, mandatoryCookies,
                            performanceCookies
                          </td>
                          <td>EY</td>
                          <td>
                            Przechowuje informacje o odpowiedzi użytkownika na
                            temat warunków cookie.
                          </td>
                          <td>Własne</td>
                          <td>30 dni</td>
                        </tr>
                      </table>
                      <p className="cookie-contentHeading">Wydajnościowe</p>
                      <table className="cookies-table">
                        <tr>
                          <th>Nazwa pliku cookie</th>
                          <th>Właściciel pliku cookie</th>
                          <th>Cel pliku cookie</th>
                          <th>Rodzaj pliku cookie</th>
                          <th>Okres ważności pliku cookie</th>
                        </tr>
                        <tr>
                          <td>{"_pk_id.<appID>.<domainHash>"}</td>
                          <td>Piwik</td>
                          <td>
                            Służy do rozpoznawania gości i przechowywania ich
                            lokalnych danych.
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>13 miesięcy</td>
                        </tr>
                        <tr>
                          <td>{"_pk_ses.<appID>.<domainHash>"}</td>
                          <td>Piwik</td>
                          <td>
                            Pokazuje aktywną sesję odwiedzającego. Jeśli plik
                            cookie nie istnieje, sesja zakończyła się ponad 30
                            minut temu i została zliczona w pliku cookie pk_id.
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>30 minut</td>
                        </tr>
                        <tr>
                          <td>stg_last_interaction</td>
                          <td>Piwik</td>
                          <td>
                            Określa, czy sesja ostatniego gościa nadal trwa, czy
                            też rozpoczęła się nowa sesja.
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>365 dni</td>
                        </tr>
                        <tr>
                          <td>stg_returning_visitor</td>
                          <td>Piwik</td>
                          <td>
                            Określa, czy odwiedzający był już w Twojej witrynie
                            oraz powrócił.
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>365 dni</td>
                        </tr>
                        <tr>
                          <td>stg_traffic_source_priority</td>
                          <td>Piwik</td>
                          <td>
                            Przechowuje typ źródła ruchu, który wyjaśnia, w jaki
                            sposób odwiedzający dotarł do Twojej witryny.
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>30 minut</td>
                        </tr>
                      </table>
                      <p className="cookie-contentHeading">Reklamowe</p>
                      <table className="cookies-table">
                        <tr>
                          <th>Nazwa pliku cookie</th>
                          <th>Właściciel pliku cookie</th>
                          <th>Cel pliku cookie</th>
                          <th>Rodzaj pliku cookie</th>
                          <th>Okres ważności pliku cookie</th>
                        </tr>
                        <tr>
                          <td>{"ln_or"}</td>
                          <td>LinkedIn</td>
                          <td>
                            Określa, czy można przeprowadzić analizę Oribi na
                            określonej domenie
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>1 dzień</td>
                        </tr>
                        <tr>
                          <td>{"_gcl_au"}</td>
                          <td>Google</td>
                          <td>
                            Używane jako tag łączący konwersję w kontenerach
                            internetowych i AMP, które można skonfigurować w
                            celu łączenia danych, gdy strony docelowe i strony
                            konwersji są osadzone w różnych domenach.
                            Wykorzystywane przez Google Adsense
                          </td>
                          <td>Podmiot zewnętrzny</td>
                          <td>90 dni</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </Dialog.Description>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
