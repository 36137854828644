import { CircleLoader } from "../CircleLoader";

import styles from "./FullscreenLoader.module.css";

type Props = { scale?: number };
export const FullscreenLoader = ({ scale = 1 }: Props) => (
  <div className={styles.fullscreenLoader}>
    <CircleLoader scale={scale} />
  </div>
);
