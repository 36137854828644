import { useMainContext } from "../context";
import { Submodule } from "../types/api";
import { isSubmoduleHidden } from "../utils/form";
import { useMainQuery } from "./useMainQuery";

export const useCurrentSubmoduleFilter = () => {
  const { data } = useMainQuery();
  const { navigation, formConditions } = useMainContext();

  return () => {
    const currentSubmodules: Submodule[] = [];
    data?.data.modules[navigation.module].submodules.forEach((submodule) => {
      if (!isSubmoduleHidden(submodule, formConditions.current)) {
        currentSubmodules.push(submodule);
      }
    });
    return currentSubmodules;
  };
};
