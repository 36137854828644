export const autoScroll = () => {
  setTimeout(() => {
    const questionTitle = document.querySelectorAll<HTMLDivElement>(
      ".question_name_container"
    );

    if (questionTitle.length > 0) {
      questionTitle[questionTitle.length - 1].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, 0);
};
